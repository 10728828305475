import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { QueryParams, ResourceService } from '@core/services/resource/resource.service';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { map } from 'rxjs/operators';
import { IMentionUser } from '@models/user';

@Injectable({
    providedIn: 'root'
})
export class CommonService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadMentions(subjectType: string, subjectSlug: string): Observable<IMentionUser[]> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('mentions', (qs: QueryStringParameters) => {
                    qs.push('subject_type', subjectType);
                    qs.push('subject_slug', subjectSlug);
                })
            )
            .pipe(map((response: any) => response.data));
    }

    autocomplete(
        subjectType: string,
        params: QueryParams = {},
        filters: { param: string; operator: string; value: any }[] = []
    ): Observable<Array<any>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('autocomplete', (qs: QueryStringParameters) => {
                    params.subject_type = subjectType;
                    params.responseFormat = 'offseted';
                    params.page_entries = params.page_entries ?? 40;

                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }

                    for (const filter of filters) {
                        qs.push(filter.param, filter.value, filter.operator);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }
}
